import React, { useEffect } from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import DropdownIndicator from './Components/DropdownIndicator';
import { SELECTED_LANGUAGE } from '../../actions/LanguageAction';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_GREY,
  GF_PERIWINKLE,
} from '../../utils/Constants';

export default function LanguageDropdown() {
  const { selectedLanguage, languages } = useSelector(
    (state) => state.language,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const customStyles = (value) => ({
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      paddingBottom: '2px',
      color: GF_DARK_BLUE,
      cursor: 'pointer',
      fontWeight: '600',

      '&:hover': {
        background: GF_PERIWINKLE,
        color: GF_DARK_BLUE,
      },

      '&:hover div': {
        color: GF_DARK_BLUE,
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: GF_PERIWINKLE,
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && state.data.id === value.id
          ? GF_DARK_BLUE
          : state.isFocused
            ? GF_CORAL
            : 'white',
      color:
        state.isSelected && state.data.id === value.id ? '#FFF' : GF_DARK_BLUE,
      '&:hover': {
        background: GF_CORAL,
        color: '#FFF',
      },
    }),

    menuList: (provided) => ({
      ...provided,
      maxHeight: '100%',
      color: GF_DARK_BLUE,
    }),

    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
      fontSize: '16px',
    }),

    placeholder: (provided) => ({
      ...provided,
      color: GF_GREY,
    }),
  });

  const { i18n } = useTranslation();

  const handleLanguageChange = (language) => {
    if (!language || language === i18n.language) return;

    i18n.changeLanguage(language);

    dispatch({ type: SELECTED_LANGUAGE, payload: language });

    history.push({
      pathname: location.pathname,
      search: `?lan=${language}`,
    });
  };

  const handleChange = (event) => {
    handleLanguageChange(event.value);
  };

  const isSupportedLanguage = (language) => {
    const supportedLanguages = languages.map((lang) => lang.value);
    return supportedLanguages.includes(language);
  };

  /**
   * useEffect hook to handle language change based on URL parameters.
   */
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const langFromUrl = params.get('lan');

    if (langFromUrl && isSupportedLanguage(langFromUrl)) {
      handleLanguageChange(langFromUrl);
    }
  }, [location.search, languages]);

  return (
    <div>
      <Select
        value={selectedLanguage}
        getOptionLabel={(option) => `${option.label}`}
        onChange={handleChange}
        placeholder={false}
        styles={customStyles(selectedLanguage)}
        options={languages}
        isSearchable={false}
        components={{
          IndicatorSeparator: null,
          DropdownIndicator,
        }}
      />
    </div>
  );
}
