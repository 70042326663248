import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import DefaultModal from '../../Modal/DefaultModal';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../Form';
import useApiHook from '../../../hooks/useAPI';
import taggingAPI from '../../../api/taggingAPI';
import AlertHandler from '../../Alert/AlertHandler';
import Counter from '../../Counter';
import { Button } from '../../Button';
import TextField from '../../Input/TextField';
import FailAlert from '../../Alert/FailAlert';
import SuccessAlert from '../../Alert/SuccessAlert';

export default function EditOrCreateTagDialog({
  onClose,
  tagData = {},
  onSuccess,
}) {
  const { t } = useTranslation();
  const methods = useForm({
    shouldUnregister: false,
    mode: 'onChange',
  });

  const [isSuccessful, setIsSuccessful] = useState(false);

  const onSuccessHandler = () => {
    setIsSuccessful(true);
    setTimeout(() => {
      setIsSuccessful(false);
    }, 5000);
    onSuccess();
  };
  async function onSubmit(data) {
    try {
      if (tagData?.id) {
        await taggingAPI.editTag(tagData.id, data);
        onSuccessHandler();
        return;
      }
      await taggingAPI.createTag(data);
      methods.reset();
      onSuccessHandler();
    } catch (error) {
      if (error.validationErrors?.name) {
        // Will display the error inline for the field.
        methods.setError('name', {
          type: 'api',
          message: error.validationErrors.name[0],
        });
        return;
      }
      // For generic errors
      methods.setError('root.serverError', {
        type: 'api',
        message: error.message,
      });
    }
  }

  function onSave(event) {
    event.preventDefault();
    methods.clearErrors('root');
    methods.handleSubmit(onSubmit)();
  }

  const {
    formState: { isSubmitting, errors },
  } = methods;

  const onCloseForm = () => {
    methods.reset();
    setIsSuccessful(false);
    onClose();
  };

  return (
    <DefaultModal
      isOpen={true}
      onClose={onCloseForm}
      size={'lg'}
      title={
        tagData?.id
          ? t('tags.manage.modal.edit.title')
          : t('tags.manage.modal.create.title')
      }
    >
      <section>
        <form onSubmit={onSave}>
          <Form {...methods}>
            <div className="grid grid-cols-1 gap-5">
              <div className="flex flex-col">
                <FormField
                  name="name"
                  defaultValue={tagData?.name || ''}
                  rules={{
                    required: t('validation.required', {
                      attribute: t('tags.manage.modal.field.name'),
                    }),
                  }}
                  render={(field) => {
                    return (
                      <FormItem className={'w-full'}>
                        <FormLabel className="font-MulishBold">
                          {t('tags.manage.modal.field.name')}
                        </FormLabel>
                        <FormControl>
                          <TextField
                            {...field}
                            placeholder={t('tags.manage.modal.field.name')}
                            autoComplete="false"
                            maxLength={50}
                          />
                        </FormControl>
                        <div className="flex flex-row items-center">
                          <FormMessage />
                          <Counter
                            string={field.value}
                            maxLength={50}
                            className={'ml-auto'}
                          />
                        </div>
                      </FormItem>
                    );
                  }}
                />
              </div>
            </div>
            {isSuccessful && (
              <div className={'mt-2'}>
                <SuccessAlert
                  message={
                    tagData?.id
                      ? t('tag.manage.modal.edit.success')
                      : t('tag.manage.modal.create.success')
                  }
                />
              </div>
            )}
            {errors?.root?.serverError && (
              <FailAlert message={errors.root.serverError.message} />
            )}
            <div className="flex justify-end space-x-5 mt-10">
              <Button
                id="formTagCancel"
                variant="secondary"
                type="button"
                size="sm"
                onClick={onCloseForm}
              >
                <span>{t('tags.manage.modal.cancel')}</span>
              </Button>
              <Button
                id="formTagSubmit"
                data-testid="formTagSubmitButton"
                variant="primary"
                type="submit"
                size="sm"
                disabled={isSubmitting || !methods.formState.isValid}
                loading={isSubmitting}
              >
                <span>
                  {tagData?.id
                    ? t('tags.manage.modal.edit.button')
                    : t('tags.manage.modal.create.button')}
                </span>
              </Button>
            </div>
          </Form>
        </form>
      </section>
    </DefaultModal>
  );
}
