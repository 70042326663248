import React from 'react';

export default function Logo() {
  return (
    <div>
      <svg
        width="120"
        height="70"
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 300"
      >
        <rect x="78.05" y="66.7" width="343.9" height="166.6" fill="none" />
        <path
          d="M249.55,150.4V116.6h15.2V101.8h-15.1V74.9h-18.6v26.9H205V91.7c0-6.6,4.4-9.8,10.1-9.8a14.55,14.55,0,0,1,5.2.8V68.1a32.07,32.07,0,0,0-9.9-1.4c-14.4,0-24,8.9-24,26.6v83.1H205V116.6h26.1v36.5c0,16.6,8.4,24.6,24.3,24.6a41.3,41.3,0,0,0,11-1.4v-14a35.53,35.53,0,0,1-5.7.5C252.65,162.8,249.55,158.2,249.55,150.4Z"
          fill="#172352"
        />
        <path
          d="M282.25,93.4a9.7,9.7,0,1,0-9.7-9.7A9.7,9.7,0,0,0,282.25,93.4Z"
          fill="#172352"
        />
        <path d="M291.55,101.8H273v74.5h18.6Z" fill="#172352" />
        <path
          d="M168.15,74a9.7,9.7,0,1,0,9.7,9.7A9.71,9.71,0,0,0,168.15,74Z"
          fill="#172352"
        />
        <path
          d="M158.85,155.2c0,12.7-2.8,16.2-5.6,17.9a19.25,19.25,0,0,1-4.6,1.9V101.9h-18.6v5.4a31,31,0,0,0-19.7-7.1c-17.6,0-31.8,14.7-31.8,32.9s14.2,32.9,31.8,32.9a31.52,31.52,0,0,0,19.8-7.1v16c-.5-.1-1-.1-1.5-.2-5.9-.8-12-1.7-18.5-1.3-12.4.7-22.6,6.1-28,14.9-7,11.3-4.5,27,5.8,36.4,6.1,5.7,14.6,8.7,23.4,8.7a39.53,39.53,0,0,0,11.2-1.7,33.63,33.63,0,0,0,17-11.5c4.7-6.1,6.6-13.1,7.6-19.7-1.3,0-2.6.1-4,.1-4.6,0-9.4-.3-14.4-.8-.7,3.4-1.7,6.8-3.6,9.2a16.1,16.1,0,0,1-8.1,5.2c-6,1.9-12.8.7-16.8-3-3.8-3.5-4.9-9.6-2.6-13.4,2.6-4.2,8.6-5.9,13.5-6.2a55.69,55.69,0,0,1,9.9.5c2.7.4,5.5.7,8.2,1,5.1.5,10,.8,14.7.8,1.4,0,2.7-.1,4.1-.1a37.42,37.42,0,0,0,15-4.9c13.2-8,14.7-23,14.7-33.5V101.8h-18.9ZM114,148.4a15,15,0,1,1,15.1-15A15.07,15.07,0,0,1,114,148.4Z"
          fill="#172352"
        />
        <path
          d="M404.45,158.3c0,12.2-3,15.6-5.7,17.2a12.53,12.53,0,0,1-3.7,1.6V101.8h-17.7v31.4s.1,10.7-11.2,10.8c-11.4-.2-11.2-10.8-11.2-10.8V101.8h-35.6V91.7c0-6.6,4.4-9.8,10.1-9.8a14.55,14.55,0,0,1,5.2.8V68.1a32.07,32.07,0,0,0-9.9-1.4c-14.4,0-24,8.9-24,26.6v83.1h18.6V116.6h17.9v17.5l.1,1.5s-.3,27.5,29,28a39.18,39.18,0,0,0,10.4-1.6v15.2h-.1c-.5-.1-1-.1-1.5-.2a93.86,93.86,0,0,0-17.8-1.3c-11.9.7-21.7,5.9-26.9,14.3-6.7,10.9-4.3,25.9,5.6,35a33.51,33.51,0,0,0,22.5,8.4,35.59,35.59,0,0,0,10.7-1.6,33.27,33.27,0,0,0,16.4-11c4.5-5.8,6.4-12.6,7.3-19-1.3,0-2.5.1-3.8.1a136.13,136.13,0,0,1-13.9-.7c-.6,3.3-1.6,6.5-3.5,8.8a15.58,15.58,0,0,1-7.8,5c-5.8,1.8-12.3.6-16.1-2.9-3.6-3.3-4.7-9.2-2.5-12.9,2.5-4,8.3-5.7,12.9-6a51.78,51.78,0,0,1,9.5.5c2.6.3,5.2.7,7.9.9a139.53,139.53,0,0,0,14.1.8c1.3,0,2.6-.1,3.9-.1a36,36,0,0,0,14.4-4.7c12.7-7.7,14.1-22.1,14.1-32.2h-17.7Z"
          fill="#172352"
        />
      </svg>
    </div>
  );
}
