/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Container from '../../UIComponents/Container';
import MVCViewList from './MVCViewList';

export default function MVCManagement() {
  const { t } = useTranslation();
  const { loadingMVCPaymentMethods } = useSelector((state) => state.mvc);

  return (
    <Container loading={loadingMVCPaymentMethods} title={t('mvc-list')}>
      <MVCViewList />
    </Container>
  );
}
