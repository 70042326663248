import React, { useState } from 'react';
import CurrencyFormat from 'react-currency-format';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  activateCardOrderByGlobalOrderRef,
  getManageSalesByDeliveryMethod,
  RESET_ACTIVATE_ORDER,
  RESET_ORDER_STATUS,
} from '../../../../actions/ManageSaleActions';
import CloseIcon from '../../../../assets/svg/CloseIcon';
import FailAlert from '../../../Alert/FailAlert';
import SuccessAlert from '../../../Alert/SuccessAlert';
import ValidationErrors from '../../../Alert/ValidationErrors';
import { RESET_VALIDATION_ERRORS } from '../../../../actions/CommonActions';
import { isSmallScreen } from '../../../../utils/Helper';
import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import { GF_DARK_BLUE } from '../../../../utils/Constants';
import SalesStatus from './SalesStatus';
import Carrier from '../../../../enums/carrier.enum';
import useAppConfig from '../../../../hooks/useAppConfig';

ReactModal.setAppElement('#root');

export default function ViewDetailOrderB2b({
  openB2BViewHandler,
  isOpen,
  data,
  closeHandler,
  selectedOrder,
  selectedCustomerType,
  chooseDate,
  endDate,
  selectedPageNumber,
  selectedCarrier = null,
}) {
  const { t } = useTranslation();
  const { isFeatureEnabled } = useAppConfig();
  const { register, handleSubmit, errors, reset } = useForm();
  const [isRetryActivate, setIsRetryActivate] = useState(false);
  const dispatch = useDispatch();
  const {
    successActivateOrder,
    failActivateOrder,
    showActivationOrderResponseView,
    loadingActivationOrder,
  } = useSelector((state) => state.manageSales);
  const { validationErrors } = useSelector((state) => state.commonReducer);

  const prepareOrder = () => {
    // open prepare order modal
    console.log('close view details');
    openB2BViewHandler(!isOpen);
  };

  const activateOrder = () => {
    setIsRetryActivate(false);
    dispatch(activateCardOrderByGlobalOrderRef(data.global_order_ref));
  };

  const reTryActivateOrder = () => {
    setIsRetryActivate(true);
    dispatch(activateCardOrderByGlobalOrderRef(data.global_order_ref));
  };

  const carrierEnabled = isFeatureEnabled('carriersInOrderSales');

  let orders = [];
  let globalOrderRef = '';
  let totalAmount = 0;
  let totalFeeAmount = 0;
  let currency = '';
  let paymentMethod = '';
  let status = '';
  let displayStatus = '';
  let variableSymbol = '';
  let deliveryMethode = '';
  let customerName = '';
  let address = '';
  let companyName = '';
  let vatNumber = '';
  const orderRows = [];

  if (data.hasOwnProperty('global_order_ref')) {
    orders = Array.from(data.orders);
    const order = data.orders[0];
    globalOrderRef = data.global_order_ref;
    status = data.status;
    displayStatus = data.display_status;
    variableSymbol = order.variable_symbol;
    currency = order.currency;
    paymentMethod = data.payment_method;
    deliveryMethode = order.home_delivery;
    customerName = `${data.first_name} ${data.last_name}`;
    address = order.address;
    companyName = order.company_name;
    vatNumber = order.vat_number;
    orders.forEach(function (order) {
      totalAmount += order.total_amount + order.fee_amount;
      totalFeeAmount += order.fee_amount;
      if (order.hasOwnProperty('customers')) {
        const customers = Array.from(order.customers);
        customers.forEach(function (customer) {
          if (orderRows.length > 0) {
            const index = orderRows.findIndex(
              (element) => element[0] == customer.amount_loaded,
            );
            if (index === -1) {
              orderRows.push([customer.amount_loaded, 1]);
            } else {
              orderRows[index][1] += 1;
            }
          } else {
            orderRows.push([customer.amount_loaded, 1]);
          }
        });
      }
    });
  }
  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel="PrepareOrder"
      className="bg-gray-700 z-50"
      style={{
        overlay: {
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(23, 35, 82, 0.6)',
          zIndex: 9999,
          backdropFilter: 'blur(8px)',
        },
        content: {
          position: 'absolute',
          top: !isSmallScreen() ? '50px' : '0px',
          left: !isSmallScreen() ? '100px' : '0px',
          right: !isSmallScreen() ? '100px' : '0px',
          bottom: !isSmallScreen() ? '100px' : '0px',

          backgroundColor: 'rgba(23, 35, 82, 0.0)',
          overflow: 'auto',
          WebkitOverflowScrolling: 'touch',
          borderRadius: '4px',
          outline: 'none',
          padding: !isSmallScreen() ? '50px' : '0px',
          marginLeft: 'auto',
          marginRight: 'auto',
          textAlign: 'center',
        },
      }}
    >
      <div className="flex flex-col  mx-auto py-4 px-10 border max-w-lg rounded shadow-lg bg-white text-gfDarkBlue font-MulishRegular">
        <div className="flex justify-between items-center ">
          {!showActivationOrderResponseView && !isRetryActivate && (
            <div className="font-MulishBold">
              {`${t('Order-information').toUpperCase()} - ${globalOrderRef}`}
            </div>
          )}
          {(showActivationOrderResponseView || isRetryActivate) && (
            <div className="font-MulishBold">
              {t('Activate-cards').toUpperCase()}
            </div>
          )}

          <button
            id="closeButton"
            className="focus:outline-none"
            onClick={() => {
              closeHandler(!isOpen);
              setIsRetryActivate(false);
              dispatch({ type: RESET_ACTIVATE_ORDER });
              dispatch({ type: RESET_ORDER_STATUS });
              dispatch({ type: RESET_VALIDATION_ERRORS });
              dispatch(
                getManageSalesByDeliveryMethod(
                  selectedOrder.homeDeliver,
                  selectedOrder.value,
                  selectedCustomerType.value,
                  '',
                  selectedPageNumber,
                  chooseDate,
                  endDate,
                  selectedCarrier?.value,
                ),
              );
            }}
          >
            <CloseIcon />
          </button>
        </div>
        {!showActivationOrderResponseView && !isRetryActivate && (
          <div className="flex flex-col">
            <div className="py-2 mb-3" data-testid="orderRecap">
              <div className="flex justify-start font-MulishBlack mb-1">{`${t('Order-Recap')}`}</div>
              <div className="flex flex-row" data-testid="amount">
                <span className="w-40 text-left font-MulishBold">{`${t('Amount')} :`}</span>
                <div className="flex justify-center">
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={totalAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    data-testid="totalAmount"
                  />
                  <span
                    className="ml-2"
                    data-testid="totalAmountCurrency"
                  >{` ${currency}`}</span>
                </div>
              </div>

              <div className="flex flex-row" data-testid="fee">
                <span className="w-40 text-left font-MulishBold">{`${t('Fee')} :`}</span>
                <div className="flex justify-center">
                  <CurrencyFormat
                    decimalScale={2}
                    fixedDecimalScale={true}
                    value={totalFeeAmount}
                    displayType={'text'}
                    thousandSeparator={true}
                    data-testid="totalFeeAmount"
                  />
                  <span
                    className="ml-2"
                    data-testid="totalFeeCurrency"
                  >{` ${currency}`}</span>
                </div>
              </div>

              <div className="flex flex-row" data-testid="paymentMethod">
                <span className="w-40 text-left font-MulishBold">{`${t('Payment-method')} :`}</span>
                <div
                  className="flex justify-center"
                  data-testid="paymentMethodValue"
                >{`${t(paymentMethod)}`}</div>
              </div>

              <div className="flex flex-row" data-testid="status">
                <span className="w-40 text-left font-MulishBold">{`${t('Status')} :`}</span>
                <span className="" data-testid="statusValue">
                  {displayStatus != null && (
                    <SalesStatus status={displayStatus} />
                  )}
                </span>
              </div>
              {variableSymbol != null && variableSymbol != '' && (
                <div className="flex flex-row" data-testid="variableSymbol">
                  <span className="w-40 text-left font-MulishBold">{`${t('Variable-symbol')} :`}</span>
                  <span className="" data-testid="variableSymbolValue">
                    {variableSymbol}
                  </span>
                </div>
              )}
            </div>
            <div className="py-2 mb-3" data-testid="purchaserInformation">
              <div className="flex justify-start font-MulishBlack mb-1">{`${t('Purchaser-Information')}`}</div>
              <div className="flex flex-row" data-testid="name">
                <span className="w-40 text-left font-MulishBold">{`${t('Name')} :`}</span>
                <span data-testid="nameValue">{customerName}</span>
              </div>
              {carrierEnabled && data.email && (
                <div className="flex flex-row" data-testid="email">
                  <span className="w-40 text-left font-MulishBold">{`${t('Email-address')} :`}</span>
                  <span data-testid="emailValue">{data.email}</span>
                </div>
              )}
              {carrierEnabled && data.phone_number && (
                <div className="flex flex-row" data-testid="phoneNumber">
                  <span className="w-40 text-left font-MulishBold">{`${t('Phone-number')} :`}</span>
                  <span data-testid="phoneNumberValue">
                    {data.phone_number}
                  </span>
                </div>
              )}
              <div className="flex flex-row" data-testid="address">
                <span className="w-40 text-left font-MulishBold">{`${t('Address')} :`}</span>
                <span data-testid="addressValue">{address}</span>
              </div>
              <div className="flex flex-row" data-testid="companyName">
                <span className="w-40 text-left font-MulishBold">{`${t('Company-name')} :`}</span>
                <span data-testid="companyNameValue">{companyName}</span>
              </div>
              <div className="flex flex-row" data-testid="vat">
                <span className="w-40 text-left font-MulishBold">{`${t('vat-id')} :`}</span>
                <span data-testid="vatValue">{vatNumber}</span>
              </div>
            </div>
            <div className="py-2 mb-3" data-testid="deliveryInformation">
              <div className="flex justify-start font-MulishBlack mb-1">{`${t('Delivery-Information')}`}</div>
              <div className="flex flex-row items-start justify-start">
                {deliveryMethode === Carrier.TO_PICK_UP && (
                  <div
                    className="flex justify-center items-center"
                    data-testid="deliveryInformationValue"
                  >
                    {String(t('Pick-up')).toUpperCase()}
                    {carrierEnabled && data?.carrier_name
                      ? ` (${data.carrier_name})`
                      : ''}
                  </div>
                )}
                {deliveryMethode === Carrier.HOME_DELIVERY && (
                  <div
                    className="flex justify-center items-center"
                    data-testid="deliveryInformationValue"
                  >
                    {String(t('Home-delivery')).toUpperCase()}
                    {carrierEnabled && data?.carrier_name
                      ? ` (${data.carrier_name})`
                      : ''}
                  </div>
                )}
              </div>
            </div>
            <div className="py-2 mb-3" data-testid="cards">
              <div className=" flex justify-start items-center w-full font-MulishBlack mb-3">{`${t('Cards')}`}</div>

              <div className="border rounded">
                <table className="w-full">
                  <thead>
                    <tr>
                      <th className="text-xs font-normal w-1/2 py-1 px-2 text-left">{`${t('Cards')}`}</th>
                      <th className="text-xs font-normal w-1/2 py-1 px-2 text-left">{`${t('quantity')}`}</th>
                      <th className="text-xs font-normal w-1/2 py-1 px-2 text-right">{`${t('Amount')}`}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderRows.map((value, key) => (
                      <tr key={key} data-testid={'cards_' + key}>
                        <td
                          className="text-left  px-2"
                          data-testid="amountAndCurrency"
                        >
                          <div className="flex font-MulishBold ">
                            <CurrencyFormat
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={value[0]}
                              displayType={'text'}
                              thousandSeparator={true}
                              data-testid="amount"
                            />
                            <span
                              className="ml-2"
                              data-testid="currency"
                            >{` ${currency}`}</span>
                          </div>
                        </td>
                        <td
                          className="font-MulishBold text-left px-2"
                          data-testid="quantity"
                        >
                          {value[1]}
                        </td>
                        <td
                          className="text-right  px-2"
                          data-testid="totalAmount"
                        >
                          <div className="flex justify-end font-MulishBold ">
                            <CurrencyFormat
                              decimalScale={2}
                              fixedDecimalScale={true}
                              value={value[0] * value[1]}
                              displayType={'text'}
                              thousandSeparator={true}
                              data-testid="totalAmount"
                            />
                            <span
                              className="ml-2"
                              data-testid="totalAmountCurrency"
                            >{` ${currency}`}</span>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            {status === 'paid' && (
              <div className="flex justify-center items-center">
                <button
                  data-testid="prepareButton"
                  onClick={prepareOrder}
                  className="py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfCoral"
                >
                  {t('Prepare-order')}
                </button>
              </div>
            )}
            {status === 'prepared' &&
              !loadingActivationOrder &&
              !successActivateOrder && (
                <div className="flex justify-center items-center w-full py-3">
                  <button
                    data-testid="activateCardButton"
                    onClick={activateOrder}
                    className="py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfCoral"
                  >
                    {t('Activate-cards')}
                  </button>
                  <button
                    data-testid="cancelButton"
                    onClick={() => {
                      closeHandler(!isOpen);
                      setIsRetryActivate(false);
                      dispatch({ type: RESET_ACTIVATE_ORDER });
                      dispatch({ type: RESET_ORDER_STATUS });
                      dispatch({ type: RESET_VALIDATION_ERRORS });
                      dispatch(
                        getManageSalesByDeliveryMethod(
                          selectedOrder.homeDeliver,
                          selectedOrder.value,
                          selectedCustomerType.value,
                          '',
                          selectedPageNumber,
                          chooseDate,
                          endDate,
                          selectedCarrier?.value,
                        ),
                      );
                    }}
                    className="ml-2 py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfPeriwinkle"
                  >
                    {t('Cancel')}
                  </button>
                </div>
              )}
            {status === 'prepared' && loadingActivationOrder && (
              <div className="flex justify-center items-center">
                <ButtonLoading color={GF_DARK_BLUE}></ButtonLoading>
              </div>
            )}
            {successActivateOrder && (
              <SuccessAlert
                title={t('Done')}
                message={t('B2b_orders_successfully_activated')}
              />
            )}
          </div>
        )}
        {(showActivationOrderResponseView || isRetryActivate) && (
          <div>
            <form autoComplete="off">
              <div className="flex flex-col">
                {failActivateOrder && (
                  <div className="py-4 space-y-5">
                    {' '}
                    <FailAlert message={failActivateOrder.message} />{' '}
                  </div>
                )}
                {validationErrors && (
                  <div className="py-4 space-y-5">
                    <div className="py-2">
                      <ValidationErrors {...validationErrors} />
                    </div>
                  </div>
                )}
                {successActivateOrder && (
                  <div className="py-4 space-y-5">
                    <SuccessAlert
                      title={t('Done')}
                      message={t('B2b_orders_successfully_activated')}
                    />
                  </div>
                )}
                {!loadingActivationOrder && failActivateOrder && (
                  <div className="flex flex-row items-center justify-start">
                    <div className="font-MulishBold mr-1 text-left">
                      {t('Error_activation_card_error')}
                    </div>
                  </div>
                )}
                <div className="flex justify-center items-center">
                  {!loadingActivationOrder && !successActivateOrder && (
                    <button
                      onClick={reTryActivateOrder}
                      className="py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfCoral"
                    >
                      <span>{t('Try-Again')}</span>
                    </button>
                  )}
                  {!loadingActivationOrder && !successActivateOrder && (
                    <button
                      onClick={() => {
                        closeHandler(!isOpen);
                        setIsRetryActivate(false);
                        dispatch({ type: RESET_ACTIVATE_ORDER });
                        dispatch({ type: RESET_ORDER_STATUS });
                        dispatch({ type: RESET_VALIDATION_ERRORS });
                        dispatch(
                          getManageSalesByDeliveryMethod(
                            selectedOrder.homeDeliver,
                            selectedOrder.value,
                            selectedCustomerType.value,
                            '',
                            selectedPageNumber,
                            chooseDate,
                            endDate,
                            selectedCarrier?.value,
                          ),
                        );
                      }}
                      className="ml-2 py-2 w-full px-4 focus:outline-none focus:border-gfPeriwinkle border rounded hover:bg-white  font-MulishBold bg-gfPeriwinkle"
                    >
                      <span>{t('Skip')}</span>
                    </button>
                  )}
                  {loadingActivationOrder && (
                    <ButtonLoading color={GF_DARK_BLUE}></ButtonLoading>
                  )}
                </div>
              </div>
            </form>
          </div>
        )}
      </div>
    </ReactModal>
  );
}
