import React, { useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import DefaultModal from '../../../Modal/DefaultModal';
import PanVerification from '../../../Modal/PanVerification';
import SendByEmailForm from '../../../Modal/SendByEmailForm';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import Receipt from '../../../Receipt/Receipt';
import { useSettings } from '../../../../hooks/useSettings';
import { format, parse } from 'date-fns';

export default function PrintOneReceipt({ isOpen, onClose, ticket }) {
  const { t } = useTranslation();
  const [isValidPAN, setIsValidPAN] = useState(false);
  const { dateFormat } = useSettings();

  const sendByData = {
    token: ticket?.token?.tokenNumber,
    ticket_number: ticket?.ticket_number,
  };

  const canSendReceiptByEmail = useMemo(() => {
    return checkLoggedInUserPermissions('send-ticket-via-email');
  }, []);

  const componentRef = useRef();
  const printTicket = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <DefaultModal
      isOpen={isOpen}
      title={t('gift-card-load-receipt')}
      onClose={() => {
        setIsValidPAN(false);
        onClose();
      }}
    >
      <>
        <div className="pb-4 space-y-5 mt-4">
          {canSendReceiptByEmail && (
            <div>
              {!isValidPAN && (
                <PanVerification
                  onSetIsValid={() => setIsValidPAN(true)}
                  ticket_number={ticket?.ticket_number}
                  token={ticket?.token?.tokenNumber ?? null}
                />
              )}

              {isValidPAN && <SendByEmailForm ticket={sendByData} />}
            </div>
          )}

          <hr className="h-1 w-full my-8"></hr>
          <div className="flex flex-row justify-between space-x-5">
            <button
              onClick={printTicket}
              className="bg-gfPeriwinkle w-full h-10 focus:outline-none rounded flex justify-center items-center hover:bg-opacity-75 py-2 px-4 font-MulishBold"
            >
              {t('Print')}
            </button>
          </div>
          <div className={'h-56 overflow-y-scroll'}>
            <Receipt
              ref={componentRef}
              ticket={{
                ...ticket,
                date: ticket?.date
                  ? format(
                      parse(ticket?.date, 'dd/MM/yyyy HH:mm:ss', new Date()),
                      dateFormat,
                    )
                  : '',
                tokens: [ticket?.token].map((token) => ({
                  ...token,
                  expiration_date: token?.expiration_date
                    ? format(
                        parse(token.expiration_date, 'yyyy-MM-dd', new Date()),
                        dateFormat,
                      )
                    : '',
                })),
                additional_products: [],
              }}
            />
          </div>
        </div>
      </>
    </DefaultModal>
  );
}
