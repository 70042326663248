import { useSelector } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { issuingAPI } from '../services/Axios';

function useTags({ perPage }) {
  const { selectedTag } = useSelector((state) => state.tag);

  return useQuery({
    queryKey: ['tag', selectedTag.id, 'tagging'],
    initialData: [],
    queryFn: async () => {
      const response = await issuingAPI.get(`tag/${selectedTag.id}/tagging`, {
        ...perPage,
      });

      return response.data.data;
    },
  });
}

function useIsTaggingEnabled({ selectedPaymentMethods }) {
  const { selectedTag } = useSelector((state) => state.tag);
  const { paymentMethods } = useSelector((state) => state.loadCard);

  const isEnabled = useMemo(() => {
    if (!selectedTag.enabled_tagging) {
      return false;
    }

    return selectedPaymentMethods.some((paymentMethod) => {
      const method = (paymentMethods || []).find(
        ({ id }) => id === paymentMethod.method_id,
      );

      return method?.is_taggable || false;
    });
  }, [paymentMethods, selectedPaymentMethods, selectedTag.enabled_tagging]);
  return { isEnabled };
}

export { useTags, useIsTaggingEnabled };
