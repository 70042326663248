import { authServerAPI, issuingAPI } from '../services/Axios';
import { APP_COUNT } from './CommonActions';
import { randomString } from '../utils/Helper';

// MVC LIST
export const LOADING_MVC_PAYMENT_METHOD_LIST =
  'LOADING_MVC_PAYMENT_METHOD_LIST';
export const RECEIVED_MVC_PAYMENT_METHOD_LIST =
  'RECEIVED_MVC_PAYMENT_METHOD_LIST';
export const ERROR_MVC_PAYMENT_METHOD_LIST = 'ERROR_MVC_PAYMENT_METHOD_LIST';
export const LOADING_MVC_PAYMENT_METHOD_FULL_LIST =
  'LOADING_MVC_PAYMENT_METHOD_FULL_LIST';

// CREATE PAYMENT METHOD
export const CREATE_PAYMENT_METHOD = 'CREATE_PAYMENT_METHOD';
export const SUCCESS_CREATE_PAYMENT_METHOD = 'SUCCESS_CREATE_PAYMENT_METHOD';
export const ERROR_CREATE_PAYMENT_METHOD = 'ERROR_CREATE_PAYMENT_METHOD';

// LOAD MVC CARD
export const LOAD_MVC_CARD = 'LOAD_MVC_CARD';
export const SUCCESS_LOAD_MVC_CARD = 'SUCCESS_LOAD_MVC_CARD';
export const ERROR_LOAD_MVC_CARD = 'ERROR_LOAD_MVC_CARD';

// UNLOAD MVC CARD
export const UNLOAD_MVC_CARD = 'UNLOAD_MVC_CARD';
export const SUCCESS_UNLOAD_MVC_CARD = 'SUCCESS_UNLOAD_MVC_CARD';
export const ERROR_UNLOAD_MVC_CARD = 'ERROR_UNLOAD_MVC_CARD';

// MVC TRANSACTIONS
export const LOAD_MVC_TRANSACTIONS = 'LOAD_MVC_TRANSACTIONS';
export const RECEIVED_MVC_TRANSACTIONS = 'RECEIVED_MVC_TRANSACTIONS';
export const ERROR_MVC_TRANSACTIONS = 'ERROR_MVC_TRANSACTIONS';

// CLOSE LOAD MODAL
export const CLOSE_LOAD_MODAL = 'CLOSE_LOAD_MODAL';

// CLOSE UNLOAD MODAL
export const CLOSE_UNLOAD_MODAL = 'CLOSE_UNLOAD_MODAL';

export const RESET_CREATE_PM = 'RESET_CREATE_PM';

export const LOAD_USER_MVC = 'LOAD_USER_MVC';
export const RECEIVED_USER_MVC = 'RECEIVED_USER_MVC';
export const ERROR_USER_MVC = 'ERROR_USER_MVC';

export const LOAD_LOGGED_IN_USER_MVC = 'LOAD_LOGGED_IN_USER_MVC';
export const RECEIVED_LOGGED_IN_USER_MVC = 'RECEIVED_LOGGED_IN_USER_MVC';
export const ERROR_LOGGED_IN_USER_MVC = 'ERROR_LOGGED_IN_USER_MVC';

export const ADDING_MVC_TO_USER = 'ADDING_MVC_TO_USER';
export const SUCCESS_ADD_MVC = 'SUCCESS_ADD_MVC';
export const FAIL_MVC_ADD = 'FAIL_MVC_ADD';
export const RESET_ADD_MVC = 'RESET_ADD_MVC';

export const getMVCPaymentMethodsFullList = () => (dispatch, getState) => {
  dispatch({ type: LOADING_MVC_PAYMENT_METHOD_FULL_LIST });

  dispatch({ type: APP_COUNT });

  const { tagID } = getState().tag;

  return issuingAPI
    .get(`tag/${tagID}/mvc-payment-method`)
    .then((success) => {
      dispatch({
        type: RECEIVED_MVC_PAYMENT_METHOD_LIST,
        payload: success.data,
      });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_MVC_PAYMENT_METHOD_LIST, payload: error });
      return error;
    });
};

export const getMVCByUser = (userID) => (dispatch, getState) => {
  dispatch({ type: LOAD_USER_MVC });

  const { tagID } = getState().tag;

  return issuingAPI
    .get(`tag/${tagID}/user/${userID}/mvc-payment-method`)
    .then((success) => {
      dispatch({ type: RECEIVED_USER_MVC, payload: success.data });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_USER_MVC, payload: error });
      return error;
    });
};

export const getLoggedInUserMVC = () => (dispatch, getState) => {
  dispatch({ type: LOAD_LOGGED_IN_USER_MVC });

  const { tagID } = getState().tag;
  const { user } = getState().auth;

  return issuingAPI
    .get(`tag/${tagID}/user/${user.id}/mvc-payment-method`)
    .then((success) => {
      dispatch({ type: RECEIVED_LOGGED_IN_USER_MVC, payload: success.data });
      return success;
    })
    .catch((error) => {
      dispatch({ type: ERROR_LOGGED_IN_USER_MVC, payload: error });
      return error;
    });
};

export const addMVCtoUser =
  (MVCList, userID, availableIds) => (dispatch, getState) => {
    dispatch({ type: ADDING_MVC_TO_USER });

    const { tagID } = getState().tag;

    const data = {
      tokens: MVCList.map(function (id) {
        return {
          token: id,
        };
      }),
    };
    // https://auth.dev.loyaltek.com/api/user/{id}/entities

    return issuingAPI
      .post(`tag/${tagID}/user/${userID}/mvc-payment-method`, { ...data })
      .then((response) => {
        dispatch({ type: SUCCESS_ADD_MVC, payload: response.data });
      })
      .catch((error) => {
        dispatch({ type: FAIL_MVC_ADD, payload: error });
      });
  };

export const createPaymentMethod =
  (
    paymentName,
    isPaymentOnTerminal,
    isLoadPromoCards,
    isTemp,
    isCorrectionMVC,
    isNewMVC,
    token,
    isB2b,
    isPartner,
    isAllowTags,
  ) =>
  (dispatch, getState) => {
    dispatch({ type: CREATE_PAYMENT_METHOD });

    const loadCardOperationID = `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`;

    const headers = { 'operation-id': loadCardOperationID };

    console.log(loadCardOperationID);

    const { tagID } = getState().tag;
    const data = {
      payment_method_name: paymentName,
      can_do_terminal_payment: isPaymentOnTerminal,
      can_load_promo_cards: isLoadPromoCards,
      is_a_new_mvc: isNewMVC,
      is_tmp: isTemp,
      is_correction_mvc: isCorrectionMVC,
      is_b2b: isB2b,
      is_partner: isPartner,
      is_taggable: isAllowTags,
    };

    if (!isNewMVC) {
      Object.assign(data, { existing_mvc_token: token });
    }

    return issuingAPI
      .post(`tag/${tagID}/mvc-payment-method`, { ...data }, { headers })
      .then((success) => {
        dispatch({
          type: SUCCESS_CREATE_PAYMENT_METHOD,
          payload: success.data,
        });
        dispatch(getMVCPaymentMethodsFullList(1));
      })
      .catch((error) => {
        dispatch({ type: ERROR_CREATE_PAYMENT_METHOD, payload: error });
        return error;
      });
  };

export const updatePaymentMethod =
  (paymentMethodId, isAllowTags) => (dispatch, getState) => {
    dispatch({ type: CREATE_PAYMENT_METHOD });

    const loadCardOperationID = `${randomString(7)}-${Math.floor(new Date().getTime() / 1000)}`;

    const headers = { 'operation-id': loadCardOperationID };

    const { tagID } = getState().tag;
    const data = {
      is_taggable: isAllowTags,
    };

    return issuingAPI
      .put(
        `tag/${tagID}/mvc-payment-method/${paymentMethodId}`,
        { ...data },
        { headers },
      )
      .then((success) => {
        dispatch({
          type: SUCCESS_CREATE_PAYMENT_METHOD,
          payload: success.data,
        });
        dispatch(getMVCPaymentMethodsFullList(1));
      })
      .catch((error) => {
        dispatch({ type: ERROR_CREATE_PAYMENT_METHOD, payload: error });
        return error;
      });
  };

export const loadPaymentMethodAmount =
  (token, amount, description) => (dispatch, getState) => {
    dispatch({ type: LOAD_MVC_CARD });

    const { tagID, tagCurrency } = getState().tag;

    return issuingAPI
      .post(`tag/${tagID}/card/${token}/load`, {
        amount,
        currency_code: tagCurrency,
        description,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_LOAD_MVC_CARD, payload: success.data });
      })
      .catch((error) => {
        dispatch({ type: ERROR_LOAD_MVC_CARD, payload: error });
        return error;
      });
  };

export const unloadPaymentMethodAmount =
  (token, amount, description) => (dispatch, getState) => {
    dispatch({ type: UNLOAD_MVC_CARD });

    const { tagID, tagCurrency } = getState().tag;

    return issuingAPI
      .post(`tag/${tagID}/card/${token}/unload`, {
        amount,
        currency_code: tagCurrency,
        description,
      })
      .then((success) => {
        dispatch({ type: SUCCESS_UNLOAD_MVC_CARD, payload: success.data });
      })
      .catch((error) => {
        dispatch({ type: ERROR_UNLOAD_MVC_CARD, payload: error });
        return error;
      });
  };

export const getMVCTransactions = (token, page) => (dispatch, getState) => {
  dispatch({ type: LOAD_MVC_TRANSACTIONS });

  const { tagID } = getState().tag;

  return issuingAPI
    .get(`tag/${tagID}/card/${token}/mvc-transfer`, {
      per_page: window?.appConfig.REACT_APP_PAGINATION_PER_PAGE_ROWS,
      page,
    })
    .then((success) => {
      dispatch({ type: RECEIVED_MVC_TRANSACTIONS, payload: success.data });
    })
    .catch((error) => {
      dispatch({ type: ERROR_MVC_TRANSACTIONS, payload: error });
      return error;
    });
};
