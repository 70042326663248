import React from 'react';
import { useTranslation } from 'react-i18next';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';

export default function SummariesByChannel({ summary }) {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col my-6">
      <div className="font-MulishBold mb-2">{t('Summary-by-channel')}</div>
      <div className="flex flex-wrap overflow-hidden border rounded bg-gfSand">
        {summary.map((item, index) => (
          <div
            key={index}
            className="my-6 px-6 w-1/6 overflow-hidden sm:my-1 sm:px-1 sm:w-1/3 md:my-4 md:px-4 md:w-1/2 lg:my-8 lg:px-8 lg:w-1/3 xl:my-2 xl:px-2 xl:w-1/6"
          >
            <div key={index} className="flex flex-col">
              <span>{item.name ?? '-'}</span>
              <div className="flex flex-row">
                {t('reports.sales.all-sales.overview.number-of-operations')}:
                &nbsp;
                <span className="text-gfLightBlue font-MulishBold">
                  {item.transactions_total}
                </span>
              </div>
              <div className="flex flex-row">
                {t('reports.sales.all-sales.overview.Total')}: &nbsp;
                <div className="flex justify-start text-gfLightBlue font-MulishBold">
                  <ShoppingCenterMoney value={item.amount} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
