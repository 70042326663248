import React, { forwardRef, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DefaultReceipt, ReceiptField, ReceiptTitleH1 } from './index';
import ShoppingCenterMoney from '../ShoppingCenterMoney';

const AdditionalProducts = (product) => {
  const { t } = useTranslation();

  return (
    <div className="my-2">
      <ReceiptField name={t('Quantity')} value={product.quantity} />
      <ReceiptField
        name={t('price')}
        value={<ShoppingCenterMoney value={product.price} />}
      />
      <ReceiptField
        name={t('VAT')}
        value={<ShoppingCenterMoney value={product.tax} />}
      />
    </div>
  );
};
export default forwardRef(function Receipt({ ticket }, ref) {
  const { tagCurrency, selectedTag } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  // Receipt VAT refactoring will be part of an entire initiative, and we will need to remove this function to centralize everything in the BE
  const vatTotal = useMemo(() => {
    if (!ticket) {
      return 0;
    }

    return (
      (ticket.tokens?.reduce((acc, token) => acc + token.fee_vat, 0) ?? 0) +
      (ticket.additional_products?.reduce(
        (acc, additional_product) =>
          acc + additional_product.tax * additional_product.quantity,
        0,
      ) ?? 0)
    );
  }, [ticket]);

  return (
    <div className="bg-gray-100" ref={ref}>
      <DefaultReceipt>
        {ticket?.user && ticket?.user !== '' && (
          <ReceiptField
            name={t('User')}
            value={`${ticket?.user ? ticket?.user : '-'}`}
          />
        )}

        <ReceiptField name={t('TRN')} value={ticket.ticket_number} />

        {ticket.desk && (
          <ReceiptField name={t('Desk')} value={ticket.desk.name} />
        )}
        <ReceiptField name={t('Date-of-Purchase')} value={ticket.date} />

        {ticket?.payments && ticket.payments.length !== 0 && (
          <div className="my-4">
            <ReceiptTitleH1 title={t('Payment-methods')} />
            {ticket.payments.map((payment, id) => (
              <ReceiptField
                key={id}
                name={payment.payment_method}
                value={<ShoppingCenterMoney value={payment.amount} />}
              />
            ))}
          </div>
        )}

        {ticket.tokens && ticket.tokens.length !== 0 && (
          <div className="my-4">
            <ReceiptTitleH1 title={t('Gift-Cards')} />

            {ticket.tokens.map((token, id) => (
              <div style={{ paddingTop: '3px', paddingBottom: '3px' }} key={id}>
                <ReceiptField
                  name={t('Token')}
                  value={token.token_number ?? '-'}
                />
                <ReceiptField
                  name={t('Amount')}
                  value={<ShoppingCenterMoney value={token.amount} />}
                />
                <ReceiptField
                  name={t('Expiry-date')}
                  value={token.expiration_date ?? '-'}
                />
                {selectedTag.purchase_fee_enabled && (
                  <>
                    <ReceiptField
                      name={t('Purchase fee')}
                      value={<ShoppingCenterMoney value={token.fee} />}
                    />
                    <ReceiptField
                      name={t('VAT')}
                      value={<ShoppingCenterMoney value={token.fee_vat} />}
                    />
                  </>
                )}
              </div>
            ))}
          </div>
        )}
        {ticket.card_load_value_total && (
          <div style={{ paddingLeft: '12px', marginTop: '10px' }}>
            <ReceiptField name={t('Cards')} value={ticket.tokens.length} />

            <ReceiptField
              name={t('Amount')}
              value={
                <ShoppingCenterMoney
                  value={ticket.card_load_value_total}
                  currency={tagCurrency}
                />
              }
            />
          </div>
        )}

        {ticket?.additional_products &&
          ticket?.additional_products.length !== 0 && (
            <div className="my-5">
              <ReceiptTitleH1 title={t('Additional-products')} />

              {ticket.additional_products.map((product) =>
                AdditionalProducts(product),
              )}

              <div style={{ paddingLeft: '12px' }}>
                <ReceiptField
                  name={t('Products')}
                  value={ticket.additional_products.length}
                />
                <ReceiptField
                  name={t('Amount')}
                  value={
                    <ShoppingCenterMoney
                      value={ticket.additional_products_total}
                    />
                  }
                />
              </div>
            </div>
          )}
        {ticket.card_load_value_total && (
          <div className="my-5">
            <ReceiptTitleH1 title={t('Order-Recap')} />
            <ReceiptField name={t('Cards')} value={ticket.tokens.length} />
            <ReceiptField
              name={t('Total-amount')}
              value={
                <ShoppingCenterMoney value={ticket.card_load_value_total} />
              }
            />
            {selectedTag.purchase_fee_enabled && (
              <ReceiptField
                name={t('Total-fees')}
                value={
                  <ShoppingCenterMoney value={ticket.card_load_fee_total} />
                }
              />
            )}

            <ReceiptField
              name={t('VAT')}
              value={<ShoppingCenterMoney value={vatTotal} />}
            />
            {selectedTag.additional_products_enabled &&
              selectedTag.has_additional_products && (
                <ReceiptField
                  name={t('additional-product-total')}
                  value={
                    <ShoppingCenterMoney
                      value={ticket.additional_products_total}
                    />
                  }
                />
              )}

            <ReceiptField
              name={t('payment-amount-total')}
              value={
                <ShoppingCenterMoney value={ticket.payment_amount_total} />
              }
            />
          </div>
        )}
      </DefaultReceipt>
    </div>
  );
});
