/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, forwardRef, useEffect, useRef } from 'react';

import { subMonths } from 'date-fns';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  Label,
} from 'recharts';
import { useDispatch, useSelector } from 'react-redux';
import format from 'date-fns/format';
import { useTranslation } from 'react-i18next';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import CurrencyFormat from 'react-currency-format';
import { Userpilot } from 'userpilot';
import {
  exportComponentAsJPEG,
  exportComponentAsPNG,
} from 'react-component-export-image';
import { useReactToPrint } from 'react-to-print';
import {
  getCSVReoprt,
  getMerchantList,
  getMerchantSales,
  LOOK_FOR_A_MERCHANT,
  RESET_MERCHANT_LIST,
  SET_FILTER_BY,
} from '../../../../actions/SalesByMerchantActions';
import MerchantList from './MerchantList';
import UnSelectedMerchantList from './UnSelectedMerchantList';
import MessageAlert from '../../../Alert/MessageAlert';
import FailAlert from '../../../Alert/FailAlert';
import { TERMINAL_CHANGED } from '../../../../actions/TerminalActions';
import { TAG_CHANGED } from '../../../../actions/TagActions';
import Container from '../../../UIComponents/Container';
import {
  GF_CORAL,
  GF_DARK_BLUE,
  GF_PERIWINKLE,
} from '../../../../utils/Constants';

import ButtonLoading from '../../../../assets/svg/ButtonLoading';
import { useSettings } from '../../../../hooks/useSettings';

export default function SalesByMerchant() {
  const [chooseDate, setChooseDate] = useState(subMonths(new Date(), 3));
  const [endDate, setEndDate] = useState(new Date());
  const [dateRange, setDateRange] = useState('3');
  const [selectedGroupBy, setSelectedGroupBy] = useState({
    label: 'Week',
    value: 'week',
  });
  const { dateFormat } = useSettings();
  const dispatch = useDispatch();
  const fromRef = useRef(null);
  const toRef = useRef(null);
  const { t } = useTranslation();
  const groupBy = [
    { label: t('Day'), value: 'day' },
    { label: t('Week'), value: 'week' },
    { label: t('Month'), value: 'month' },
    { label: t('Year'), value: 'year' },
  ];

  const { appCount } = useSelector((state) => state.commonReducer);

  const GROUP_BY = 'month';

  useEffect(() => {
    Userpilot.reload();

    if (appCount === 0) {
      dispatch({ type: RESET_MERCHANT_LIST });
      dispatch(
        getMerchantList(
          format(chooseDate, 'yyyy-MM-dd'),
          format(endDate, 'yyyy-MM-dd'),
        ),
      );
    }

    window.addEventListener(TAG_CHANGED, () => {
      dispatch({ type: RESET_MERCHANT_LIST });
      dispatch(
        getMerchantList(
          format(fromRef.current.props.selected, 'yyyy-MM-dd'),
          format(toRef.current.props.selected, 'yyyy-MM-dd'),
        ),
      );
    });

    window.addEventListener(TERMINAL_CHANGED, () => {
      dispatch({ type: RESET_MERCHANT_LIST });
      dispatch(
        getMerchantList(
          format(fromRef.current.props.selected, 'yyyy-MM-dd'),
          format(toRef.current.props.selected, 'yyyy-MM-dd'),
        ),
      );
    });

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
    };
  }, []);

  const {
    loadingMerchantList,
    receivedMerchantList,
    errorSalesMerchantList,

    loadingSalesByMerchant,
    receivedSalesByMerchant,
    errorSalesByMerchant,
    selectedMerchants,
    unSelectedMerchants,
    filteredMerchant,
    checkSalesEmpty,
    dates,
  } = useSelector((state) => state.salesByMerchant);

  const componentRef = useRef();

  const { tagCurrency } = useSelector((state) => state.tag);

  const { selectedLanguage } = useSelector((state) => state.language);

  const DPCustomInput = forwardRef(({ value, onClick }, ref) => (
    <input
      ref={ref}
      value={value}
      onClick={onClick}
      readOnly={true}
      className="border cursor-pointer text-sm text-center w-full rounded  hover:bg-gfPeriwinkle  border-gfPeriwinkle px-8 h-10 focus:outline-none"
    />
  ));

  const fromDatePickHandler = (date) => {
    setDateRange(-1);
    setChooseDate(date);
    dispatch({ type: RESET_MERCHANT_LIST });
    dispatch(
      getMerchantList(
        format(date, 'yyyy-MM-dd'),
        format(endDate, 'yyyy-MM-dd'),
      ),
    );
  };

  const toDatePickHandler = (date) => {
    setDateRange(-1);
    setEndDate(date);
    dispatch({ type: RESET_MERCHANT_LIST });
    dispatch(
      getMerchantList(
        format(chooseDate, 'yyyy-MM-dd'),
        format(date, 'yyyy-MM-dd'),
      ),
    );
  };

  const dateRangeHandler = (range) => {
    setDateRange(range);
    setEndDate(new Date());
    setChooseDate(subMonths(new Date(), parseInt(range)));
    dispatch({ type: RESET_MERCHANT_LIST });
    dispatch(
      getMerchantList(
        format(subMonths(new Date(), parseInt(range)), 'yyyy-MM-dd'),
        format(new Date(), 'yyyy-MM-dd'),
      ),
    );
  };

  const getChartHandler = () => {
    dispatch(
      getMerchantSales(
        format(chooseDate, 'yyyy-MM-dd'),
        format(endDate, 'yyyy-MM-dd'),
        selectedGroupBy.value,
      ),
    );
  };

  const customStyles = (value) => ({
    placeholder: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
    }),
    control: (provided) => ({
      ...provided,
      boxShadow: 'none',
      borderColor: GF_PERIWINKLE,
      borderRadius: '4px',
      minHeight: '40px',
      paddingBottom: '2px',
      color: GF_DARK_BLUE,
      cursor: 'pointer',
      minWidth: '230px',

      '&:hover': {
        background: GF_PERIWINKLE,
        color: GF_DARK_BLUE,
        borderColor: GF_PERIWINKLE,
      },

      '&:hover div': {
        color: GF_DARK_BLUE,
      },

      '&:focus': {
        borderColor: GF_PERIWINKLE,
        background: '#F7F7F8',
      },
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: '4px',
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor:
        state.isSelected && state.data.value === value.value
          ? GF_DARK_BLUE
          : state.isFocused
            ? GF_CORAL
            : 'white',
      padding: 8,
      fontSize: '16px',
      color:
        state.isSelected && state.data.value === value.value
          ? '#FFF'
          : GF_DARK_BLUE,
      '&:hover': {
        background: '#FF666E',
        color: '#FFF',
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: GF_DARK_BLUE,
      fontSize: '18px',
    }),
  });

  const CustomizedTooltip = ({ active, payload, label }) => {
    if (active && label !== undefined) {
      return (
        <div className="flex flex-col p-4 border bg-white rounded border-gfDarkSand font-MulishBold text-sm space-y-2 shadow-md">
          {<div>{label}</div>}
          {payload.map((item, index) => (
            <div key={index} className="flex flex-row">
              <span style={{ color: item.color }} className="mr-1">
                {item.name}:
              </span>
              <div className="flex justify-start text-gfLightBlue">
                <CurrencyFormat
                  decimalScale={2}
                  fixedDecimalScale={true}
                  value={item.payload.amount}
                  displayType={'text'}
                  thousandSeparator={true}
                />
                <span className="ml-2 text-xs">{` ${tagCurrency}`}</span>
              </div>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderColorfulLegendText = (value, entry) => {
    const { color } = entry;

    return (
      <span className="font-MulishBold py-4 px-4" style={{ color }}>
        {value}
      </span>
    );
  };

  const groupByChanged = (event) => {
    setSelectedGroupBy(event);
    dispatch({ type: SET_FILTER_BY, payload: event.value });
    dispatch(
      getMerchantSales(
        format(chooseDate, 'yyyy-MM-dd'),
        format(endDate, 'yyyy-MM-dd'),
        event.value,
      ),
    );
  };

  const searchHandler = (event) => {
    dispatch({ type: LOOK_FOR_A_MERCHANT, payload: event.currentTarget.value });
  };

  const getReport = () => {
    const from = format(chooseDate, 'yyyy-MM-dd');
    const to = format(endDate === null ? chooseDate : endDate, 'yyyy-MM-dd');
    dispatch(getCSVReoprt(from, to, selectedGroupBy.value));
  };

  const printReceipt = useReactToPrint({
    content: () => componentRef.current,
  });

  const dateTickFormatter = (tickItem) => {
    if (selectedGroupBy.value !== 'week') {
      return format(new Date(tickItem), dateFormat);
    }
    return tickItem;
  };

  return (
    <Container
      title={t('sales-by-merchant')}
      loading={loadingMerchantList || loadingSalesByMerchant}
    >
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-flow-col xl:grid-flow-col gap-5 items-end">
        <div className="flex flex-col w-full">
          <div className="flex items-center py-1">{t('Choose-the-date')}:</div>{' '}
          <ReactDatePicker
            showMonthDropdown
            showYearDropdown
            disabled={loadingSalesByMerchant}
            dateFormat="dd/MM/yyyy"
            customInput={<DPCustomInput />}
            selected={chooseDate}
            onChange={(date) => fromDatePickHandler(date)}
            locale={selectedLanguage.value}
          />
        </div>
        <div className="flex flex-col w-full">
          <div className="flex items-center   py-1">
            {`${t('End-date')}(${t('Optional')})`}
          </div>
          <ReactDatePicker
            showMonthDropdown
            showYearDropdown
            disabled={loadingSalesByMerchant}
            dateFormat="dd/MM/yyyy"
            selected={endDate}
            onChange={(date) => toDatePickHandler(date)}
            customInput={<DPCustomInput />}
            locale={selectedLanguage.value}
          />
        </div>
        <div id="switch-field">
          <input
            disabled={false}
            type="radio"
            id="radio-zero-date-range"
            name="switch-zero-date-range"
            value="1"
            checked={dateRange === '1'}
            onChange={(e) => {
              dateRangeHandler(e.target.value);
            }}
          />
          <label
            htmlFor="radio-zero-date-range"
            className="text-gfDarkBlue font-medium"
          >
            1 {t('Month')}
          </label>
          <input
            disabled={false}
            type="radio"
            id="radio-one-date-range"
            name="switch-one-date-range"
            value="3"
            checked={dateRange === '3'}
            onChange={(e) => {
              dateRangeHandler(e.target.value);
            }}
          />
          <label
            htmlFor="radio-one-date-range"
            className="text-gfDarkBlue font-medium"
          >
            3 {t('Months')}
          </label>
          <input
            disabled={false}
            type="radio"
            id="radio-two-date-range"
            name="switch-one-date-range"
            value="6"
            checked={dateRange === '6'}
            onChange={(e) => {
              dateRangeHandler(e.target.value);
            }}
          />
          <label
            htmlFor="radio-two-date-range"
            className="text-gfDarkBlue font-medium"
          >
            6 {t('Months')}
          </label>
          <input
            disabled={false}
            type="radio"
            id="radio-three-date-range"
            name="switch-one-date-range"
            value="12"
            checked={dateRange === '12'}
            onChange={(e) => {
              dateRangeHandler(e.target.value);
            }}
          />
          <label
            htmlFor="radio-three-date-range"
            className="text-gfDarkBlue font-medium"
          >
            1 {t('Year')}
          </label>
        </div>
        <div className="flex flex-col w-full">
          <div className="flex items-center py-1">{t('Group-By')}:</div>{' '}
          <Select
            styles={customStyles(selectedGroupBy)}
            className="block"
            options={groupBy}
            value={selectedGroupBy}
            onChange={groupByChanged}
          />
        </div>
      </div>
      <div className="px-4 py-5 sm:px-6">
        {receivedSalesByMerchant && checkSalesEmpty && (
          <div className="flex flex-row justify-end items-center py-5">
            <button
              className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
              onClick={() => exportComponentAsPNG(componentRef)}
            >
              {t('save-png')}
            </button>
            <button
              className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
              onClick={() => exportComponentAsJPEG(componentRef)}
            >
              {t('save-jpeg')}
            </button>
            <button
              className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
              onClick={printReceipt}
            >
              {t('export-pdf')}
            </button>
            <button
              className="bg-gfCoral hover:bg-opacity-75 rounded py-2 px-4 mx-2"
              onClick={getReport}
            >
              {t('export-csv')}
            </button>
          </div>
        )}
        <div className="flex flex-col mx-auto justify-center items-center">
          <div className="mt-10">
            {receivedMerchantList && receivedMerchantList.length === 0 && (
              <MessageAlert message={t('Merchant-list-is-empty')} />
            )}
            {receivedSalesByMerchant && !checkSalesEmpty && (
              <MessageAlert
                message={t('There-are-no-information-to-display-for-this-date')}
              />
            )}
            {errorSalesMerchantList && (
              <FailAlert message={errorSalesMerchantList.message} />
            )}
            {errorSalesByMerchant && (
              <FailAlert message={errorSalesByMerchant.message} />
            )}
          </div>

          <div className="mt-10 flex justify-center w-full h-800">
            <div className="p-2 flex justify-center items-center">
              {loadingMerchantList && <ButtonLoading color={GF_DARK_BLUE} />}
            </div>
            {receivedMerchantList &&
              receivedMerchantList.length !== 0 &&
              selectedMerchants &&
              unSelectedMerchants &&
              filteredMerchant && (
                <div className="flex flex-col w-2/12 space-y-5">
                  <div className="p-2 flex justify-center items-center font-MulishBold">
                    {t('Filters')}
                  </div>
                  <input
                    onChange={searchHandler}
                    className="w-full py-2 px-2 border-gfPeriwinkle border focus:outline-none rounded"
                    placeholder={t('Look-for-a-merchant')}
                  />
                  <div className="space-y-5 overflow-auto">
                    <MerchantList />
                    <button
                      disabled={loadingSalesByMerchant}
                      onClick={getChartHandler}
                      className="border  w-full flex flex-row rounded justify-center items-center  bg-gfCoral hover:bg-opacity-75 font-MulishBold  px-4 py-2 focus:outline-none"
                    >
                      {t('Go')}!
                    </button>
                    <UnSelectedMerchantList />
                  </div>
                </div>
              )}
            <div
              className="flex flex-col w-10/12 "
              ref={componentRef}
              id="myDiv"
            >
              {receivedSalesByMerchant && checkSalesEmpty && (
                <div className="flex flex-row justify-center items-center font-MulishBold py-4 text-2xl">{`${t('sales-by-merchant')}  -> ${format(chooseDate, dateFormat)} - ${format(endDate, dateFormat)}`}</div>
              )}
              {receivedSalesByMerchant && checkSalesEmpty && (
                <div className="grid grid-cols-1 md:grid-cols-3 gap-5 items-end px-5 mb-4">
                  {receivedSalesByMerchant.map((sale, index) => (
                    <div
                      key={index}
                      style={{ borderColor: sale.color }}
                      className="border-b-8 rounded border w-full py-2 px-2 flex flex-col justify-center items-center"
                    >
                      <span className="font-MulishBold text-gfLightBlue text-sm">
                        {sale.merchant_name}
                      </span>
                      <div className="flex justify-start text-gfLightBlue font-MulishBlack">
                        <CurrencyFormat
                          decimalScale={2}
                          fixedDecimalScale={true}
                          value={sale.total_amount}
                          displayType={'text'}
                          thousandSeparator={true}
                        />
                        <span className="ml-2 text-xs">{` ${tagCurrency}`}</span>
                      </div>
                    </div>
                  ))}
                </div>
              )}

              {receivedSalesByMerchant && checkSalesEmpty && (
                <div className="flex flex-col w-full h-600">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      width={500}
                      height={300}
                      margin={{
                        top: 20,
                        right: 40,
                        left: 40,
                        bottom: 60,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey={
                          selectedGroupBy.value !== 'week' ? 'date' : 'week'
                        }
                        allowDuplicatedCategory={false}
                        tickFormatter={dateTickFormatter}
                        angle={90}
                        minTickGap={-200}
                        padding={{ left: 20, right: 20, top: 20, bottom: 20 }}
                        tick={{ fontSize: 12 }}
                        dy={40}
                        stroke={GF_DARK_BLUE}
                        allowDataOverflow
                      />
                      <YAxis dataKey="amount" stroke={GF_DARK_BLUE}>
                        <Label
                          className="font-MulishBold"
                          angle={-90}
                          position="insideLeft"
                        >
                          {t('Amount')}
                        </Label>
                      </YAxis>
                      <Tooltip content={CustomizedTooltip} />
                      <Legend
                        verticalAlign="top"
                        formatter={renderColorfulLegendText}
                        margin={{
                          top: 50,
                          right: 0,
                          left: 0,
                          bottom: 0,
                        }}
                      />
                      <Line data={dates} />
                      {receivedSalesByMerchant &&
                        receivedSalesByMerchant.map((s) => (
                          <Line
                            dataKey="amount"
                            data={s.sales}
                            name={s.merchant_name}
                            key={s.merchant_id}
                            stroke={s.color}
                          />
                        ))}
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
