import React from 'react';
import { useTranslation } from 'react-i18next';
import DefaultModal from '../../Modal/DefaultModal';
import taggingAPI from '../../../api/taggingAPI';
import { Button } from '../../Button';

export default function TagDeleteConfirmation({ onClose, tagData, onSuccess }) {
  const { t } = useTranslation();

  const onSuccessHandler = () => {
    onSuccess();
  };
  async function yesOption() {
    await taggingAPI.deleteTag(tagData.id);
    onSuccessHandler();
  }

  return (
    <DefaultModal
      isOpen={true}
      onClose={onClose}
      size={'md'}
      title={t('tag.manage.modal.delete.title')}
    >
      <section className={'text-left py-4 space-y-6'}>
        <div className="flex flex-row justify-center space-x-20 py-5">
          <Button
            onClick={yesOption}
            id="formTagDeleteYes"
            data-testid="formTagDeleteYesButton"
            variant="primary"
            type="button"
            size="sm"
          >
            {t('Yes')}
          </Button>
          <Button
            onClick={onClose}
            id="formTagDeleteNo"
            data-testid="formTagDeleteNoButton"
            variant="secondary"
            type="button"
            size="sm"
          >
            {t('No')}
          </Button>
        </div>
      </section>
    </DefaultModal>
  );
}
