import { useSelector } from 'react-redux';

export function useCardTransactions() {
  const {
    cardTransactionMeta: meta,
    cardTransactionInfo: data,
    errorTransactionInfo: error,
  } = useSelector((state) => state.cardInformation);

  return {
    data: data || [],
    error,
    isError: error !== null,
    pagination: {
      isPaginated: meta ? meta.total > meta.per_page : false,
      totalPages: meta ? meta.total / meta.per_page : 0,
    },
  };
}
