import React, { useMemo } from 'react';
import { cn } from '../../utils';

const Counter = ({ className, string = '', maxLength = 50 }) => {
  const stringLength = useMemo(() => string.length, [string]);
  return (
    <span className={cn('font-MulishBold text-grey-200', className)}>
      {stringLength}/{maxLength}
    </span>
  );
};

export default Counter;
