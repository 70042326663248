import { useEffect } from 'react';

const useCookieBanner = (language) => {
  useEffect(() => {
    const cleanUpCookieScript = () => {
      const cookieScripts = document.querySelectorAll(
        'script[src*="cookie-script.com"]',
      );
      cookieScripts.forEach((script) => script.remove());

      if (window.CookieScript) {
        delete window.CookieScript;
      }
    };

    const injectCookieScript = (lang) => {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `//cdn.cookie-script.com/s/1e88b461c83ede5d3d93d49e8327adbd.js?t=${new Date().getTime()}`;
      script.setAttribute('data-language', lang);
      document.body.appendChild(script);
    };

    cleanUpCookieScript();
    injectCookieScript(language);
  }, [language]); // Re-run the effect whenever the language changes
};

export default useCookieBanner;
