import React, { useMemo } from 'react';
import Table from '../../../Table';
import { Link } from 'react-router-dom';
import { checkLoggedInUserPermissions } from '../../../../utils/Helper';
import ShoppingCenterMoney from '../../../ShoppingCenterMoney';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

export default function Row({ fee }) {
  const { selectedTag } = useSelector((state) => state.tag);
  const { t } = useTranslation();

  const canAccessCardInformation = useMemo(
    () => checkLoggedInUserPermissions('card-information'),
    [checkLoggedInUserPermissions],
  );

  const getTokenToDisplay = (token) => {
    if (token === '' || token === '0' || !canAccessCardInformation) {
      return '-';
    } else {
      return (
        <Link
          target="_blank"
          rel="noopener noreferrer"
          className="underline"
          to={`/gift-cards/card-information?token=${token}`}
        >
          {token}
        </Link>
      );
    }
  };

  const getProductName = (fee) => {
    if (fee.name !== '' && fee.quantity > 1) {
      return <span>{`${fee.quantity} x ${fee.name}`}</span>;
    } else if (fee.name !== '' && fee.quantity === 1) {
      return <span>{`${fee.name}`}</span>;
    } else {
      return '-';
    }
  };

  return (
    <>
      <Table.Row key={fee.transaction_number}>
        <Table.Cell>{fee.date}</Table.Cell>
        <Table.Cell>
          {!fee.order_reference?.length ? fee.ticket_number : '-'}
        </Table.Cell>
        <Table.Cell>{fee.order_reference || '-'}</Table.Cell>
        <Table.Cell>{getTokenToDisplay(fee.token)}</Table.Cell>
        <Table.Cell>
          {fee.type !== ''
            ? t(`reports.finance.fees.fees-types.${fee.type}`)
            : '-'}
        </Table.Cell>
        {selectedTag.additional_products_enabled && (
          <Table.Cell>{getProductName(fee)}</Table.Cell>
        )}
        <Table.Cell>
          <ShoppingCenterMoney value={fee.fee_excl} />
        </Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney className={'font-MulishBold'} value={fee.vat} />
        </Table.Cell>
        <Table.Cell>
          <ShoppingCenterMoney value={fee.fee_incl} />
        </Table.Cell>
      </Table.Row>
    </>
  );
}
