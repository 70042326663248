import { useSelector } from 'react-redux';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { issuingAPI } from '../services/Axios';
import { useMerchantCodes } from './useMerchantCodes';

function useMerchants({ params = {} } = {}) {
  const { tagCardBrands } = useSelector((state) => state.tag);

  return useQuery({
    queryKey: ['card-brand', tagCardBrands, 'merchants', { params }],
    initialData: [],
    queryFn: async () => {
      const response = await issuingAPI.get(
        `card-brand/${tagCardBrands}/merchants`,
        {
          params,
        },
      );

      return response.data.data;
    },
  });
}

function useMerchantCategories() {
  const { tagID } = useSelector((state) => state.tag);
  const { getDescriptionForCode } = useMerchantCodes();

  return useQuery({
    queryKey: ['tag', tagID, 'merchant-codes'],
    initialData: [],
    queryFn: async () => {
      const response = await issuingAPI.get(`tag/${tagID}/merchant-codes`);

      return response.data;
    },
    select: (data) => {
      return data.map((category) => ({
        ...category,
        name: getDescriptionForCode(category.code),
      }));
    },
  });
}

function useCreateMerchant() {
  const { tagCardBrands } = useSelector((state) => state.tag);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload) => {
      const response = await issuingAPI.post(
        `card-brand/${tagCardBrands}/merchants`,
        payload,
      );

      return response.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['card-brand', tagCardBrands, 'merchants']);
    },
  });
}

export { useMerchants, useCreateMerchant, useMerchantCategories };
