import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  RESET_UPDATING_TAG,
  TAG_CHANGED,
  updateTag,
  uploadLogoBWFile,
  uploadLogoFile,
} from '../../../actions/TagActions';
import { TERMINAL_CHANGED } from '../../../actions/TerminalActions';
import Container from '../../UIComponents/Container';
import SuccessAlert from '../../Alert/SuccessAlert';
import FailAlert from '../../Alert/FailAlert';
import ButtonLoading from '../../../assets/svg/ButtonLoading';
import ValidationErrors from '../../Alert/ValidationErrors';

export default function EditShoppingCenter() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { validationErrors } = useSelector((state) => state.commonReducer);
  const {
    selectedTag,
    updatingTag,
    successUpdatingTag,
    failUpdatingTag,
    updatedLogo,
    updatedLogoBW,
  } = useSelector((state) => state.tag);
  const { register, handleSubmit, errors, reset, setValue } = useForm({
    shouldValidate: true,
    shouldDirty: true,
  });
  const [selectedLogoFile, setSelectedLogoFile] = useState();
  const [logoFileName, setLogoFileName] = useState();
  const [isLOgoFilePicked, setIsLogoFilePicked] = useState(false);
  const [noLogoSelected, setNoLogoSelected] = useState(false);
  const [selectedLogoBWFile, setSelectedLogoBWFile] = useState();
  const [BWFileName, setBWFileName] = useState('');
  const [isLOgoBWFilePicked, setIsLogoBWFilePicked] = useState(false);
  const [noBWSelected, setNoBWSelected] = useState(false);

  const onSubmit = (data) => {
    if (isLOgoFilePicked) {
      const logoFileData = new FormData();
      logoFileData.append('image', selectedLogoFile);
      dispatch(uploadLogoFile(logoFileData));
    }

    if (isLOgoBWFilePicked) {
      const logoBWFileData = new FormData();
      logoBWFileData.append('image', selectedLogoBWFile);
      dispatch(uploadLogoBWFile(logoBWFileData));
    }

    dispatch(updateTag(data, history));
  };

  useEffect(() => {
    window.addEventListener(TAG_CHANGED, () => {});

    window.addEventListener(TERMINAL_CHANGED, () => {});

    const fields = [
      'name',
      'address',
      'zip',
      'city',
      'country_code',
      'support_phone',
      'support_email',
      'website',
      'ticket_header',
      'ticket_footer',
      'logo_file',
      'logo_file_bw',
      'ticket_footer_invoice',
    ];

    if (selectedTag) {
      setTimeout(
        () => fields.forEach((field) => setValue(field, selectedTag[field])),
        600,
      );
    }

    return () => {
      window.removeEventListener(TAG_CHANGED, () => {});
      window.removeEventListener(TERMINAL_CHANGED, () => {});
    };
  }, []);

  const onLogoFileChange = (event) => {
    setSelectedLogoFile(event.target.files[0]);
    setLogoFileName(event.target.files[0].name);
    setIsLogoFilePicked(true);
  };

  const onLogoFileBWChange = (event) => {
    setBWFileName(event.target.files[0].name);
    setSelectedLogoBWFile(event.target.files[0]);
    setIsLogoBWFilePicked(true);
  };

  const onLogoError = (event, id) => {
    setNoLogoSelected(true);
  };

  const onBWError = (event, id) => {
    setNoBWSelected(true);
  };

  const resetUpdating = () => {
    setTimeout(function () {
      dispatch({ type: RESET_UPDATING_TAG });
    }, 3000);
  };

  return (
    <Container title={`Edit Shopping Center - ${selectedTag.name}`}>
      <div className="mx-auto max-w-lg">
        <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <div className="px-5 pb-5">
              <div className="flex mb-5">
                <div className="flex-grow">
                  <div className="flex flex-col">
                    <div className="font-MulishBold">
                      {t('Supported-Phone')}
                    </div>
                    <input
                      name="support_phone"
                      maxLength="40"
                      ref={register({ required: false })}
                      type="text"
                      autoComplete="off"
                      className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                    />
                    {errors.support_phone && (
                      <p className="text-xs text-gfCoral p-1">
                        {t('Required')}
                      </p>
                    )}
                  </div>
                </div>
              </div>

              <div className="flex-grow mb-5">
                <div className="flex flex-col">
                  <div className="font-MulishBold">{t('Supported-Email')}</div>
                  <input
                    name="support_email"
                    maxLength="40"
                    ref={register({ required: false })}
                    type="text"
                    autoComplete="off"
                    className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                  />
                  {errors.support_email && (
                    <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                  )}
                </div>
              </div>

              <div className="flex-grow mb-5">
                <div className="flex flex-col">
                  <div className="font-MulishBold">{t('Website')}</div>
                  <input
                    name="website"
                    maxLength="40"
                    ref={register({ required: false })}
                    type="text"
                    autoComplete="off"
                    className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                  />
                  {errors.website && (
                    <p className="text-xs text-gfCoral p-1">{t('Required')}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-col">
                <div className="flex-grow">
                  <div className="flex flex-col mb-5">
                    <div className="font-MulishBold">{t('Ticket-Header')}</div>

                    <textarea
                      ref={register({ required: false })}
                      name="ticket_header"
                      className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                    ></textarea>
                    {errors.ticket_header && (
                      <p className="text-xs text-gfCoral p-1">
                        {t('Required')}
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex-grow">
                  <div className="flex flex-col mb-5">
                    <div className="font-MulishBold">{t('Ticket-Footer')}</div>

                    <textarea
                      ref={register({ required: false })}
                      name="ticket_footer"
                      className="w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                    ></textarea>
                    {errors.ticket_footer && (
                      <p className="text-xs text-gfCoral p-1">
                        {t('Required')}
                      </p>
                    )}
                  </div>
                </div>
                {selectedTag.is_giftify_selling_party && (
                  <div className="flex-grow">
                    <div className="flex flex-col mb-5">
                      <div className="font-MulishBold">
                        {t('Ticket-Footer-invoice')}
                      </div>
                      <textarea
                        disabled={true}
                        ref={register({ required: false })}
                        name="ticket_footer_invoice"
                        value={t('invoice-footer-message', {
                          invoiceServiceUrl:
                            process.env.REACT_APP_INVOICE_SERVICE_URL,
                        }).replace(/&#x2F;/g, '/')}
                        className="bg-gray-200 w-full border border-gfPeriwinkle rounded px-4 py-2  placeholder-gfGrey focus:outline-none focus:border-gfPeriwinkle"
                      ></textarea>
                      {errors.ticket_footer_invoice && (
                        <p className="text-xs text-gfCoral p-1">
                          {t('Required')}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                <div className="py-5 flex content-center">
                  {t('The-following-image-types-are-allowed')}
                </div>
                <div className="flex flex-col mb-5">
                  <div className="font-MulishBold">{t('Logo-File')}</div>
                  <div className="flex flex-col">
                    <div className="border rounded p-4 flex flex-row">
                      {/* <button onClick={onFileUpload}>Upload!</button> */}
                      <div className="w-32 h-24 border rounded flex justify-center items-center border-gfPeriwinkle">
                        {!noLogoSelected ? (
                          <img
                            className="modalImage w-full h-full object-contain"
                            src={
                              updatedLogo !== null
                                ? updatedLogo.url
                                : `${window?.appConfig.REACT_APP_IMAGE_URL}/${
                                    selectedTag.logo_file
                                  }`
                            }
                            onError={(event) => onLogoError(event)}
                            alt=""
                          />
                        ) : (
                          <div className="font-MulishBlack">
                            {t('No-Image')}
                          </div>
                        )}
                      </div>

                      {/* <input type="file" onChange={onLogoFileChange} className="mx-5" /> */}
                      <div>
                        <div className="upload-btn-wrapper">
                          <button className="btnr" title={t('Choose-File')}>
                            {t('Choose-File')}
                          </button>
                          <span>{logoFileName}</span>
                          <input
                            type="file"
                            name="myfile"
                            title={t('Choose-File')}
                            onChange={onLogoFileChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-col mb-5">
                  <div className="font-MulishBold">{t('Logo-File-B&W')}</div>
                  <div className="border rounded p-4 flex flex-row">
                    {/* <button onClick={onFileUpload}>Upload!</button> */}
                    <div className="w-32 h-24 border rounded flex justify-center items-center border-gfPeriwinkle">
                      {!noBWSelected ? (
                        <img
                          className="modalImage w-full h-full object-contain"
                          src={
                            updatedLogoBW !== null
                              ? updatedLogoBW.url
                              : `${window?.appConfig.REACT_APP_IMAGE_URL}/${
                                  selectedTag.logo_file_bw
                                }`
                          }
                          onError={(event) => onBWError(event)}
                          alt=""
                        />
                      ) : (
                        <div className="font-MulishBlack">{t('No-Image')}</div>
                      )}
                    </div>

                    {/* <input type="file" onChange={onLogoFileBWChange} className="mx-5" /> */}

                    <div>
                      <div className="upload-btn-wrapper">
                        <button className="btnr" title={t('Choose-File')}>
                          {t('Choose-File')}
                        </button>
                        <span>{BWFileName}</span>
                        <input
                          type="file"
                          name="myfile"
                          title={t('Choose-File')}
                          onChange={onLogoFileBWChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row justify-between space-x-10">
                  <button
                    type="submit"
                    className="w-full flex flex-row border rounded px-4 py-2 justify-center  font-MulishBold bg-gfCoral focus:outline-none hover:bg-opacity-75  cursor-pointer"
                  >
                    {updatingTag && <ButtonLoading color={'#4A5568'} />}
                    {t('Update')}
                  </button>
                </div>

                {validationErrors === null &&
                  successUpdatingTag !== null &&
                  successUpdatingTag.length === 0 && (
                    <div className="my-4">
                      <SuccessAlert title={'Success'} message={'Updated !'} />
                    </div>
                  )}

                {failUpdatingTag && (
                  <div className="my-4">
                    <FailAlert message={failUpdatingTag.message} />
                    {resetUpdating()}
                  </div>
                )}
                {validationErrors && (
                  <div className="py-2">
                    <ValidationErrors {...validationErrors} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
    </Container>
  );
}
