import ReactModal from 'react-modal';
import React, { useEffect, useRef } from 'react';
import CloseIcon from '../../assets/svg/CloseIcon';
import { cn } from '../../utils';

const defaultStyle = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(23, 35, 82, 0.6)',
    zIndex: 9999,
    backdropFilter: 'blur(12px)',
  },
  content: {
    overflow: 'auto',
    transform: 'translate(-50%,-50%)',
    WebkitOverflowScrolling: 'touch',
    outline: 'none',
    maxHeight: '90vh',
  },
};

export default function DefaultModal({
  children,
  isOpen,
  contentLabel,
  style,
  title,
  className,
  onClose,
  size = 'md',
}) {
  const modalRef = useRef();

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isOpen]);

  return (
    <ReactModal
      isOpen={isOpen}
      contentLabel={contentLabel}
      className={cn(
        'z-50 absolute w-full mx-auto p-8 border max-w-lg rounded-md shadow-lg bg-white text-gfDarkBlue font-MulishRegular top-1/2 left-1/2',
        {
          'max-w-3xl': size === 'lg',
          'max-w-6xl': size === 'xl',
        },
        className,
      )}
      style={{
        ...defaultStyle,
        ...style,
      }}
    >
      <div data-testid={contentLabel} className="flex flex-col">
        <div className="flex flex-row justify-between items-center">
          <div>{title && <h1 className={'font-semibold'}>{title}</h1>}</div>
          <button
            onClick={() => {
              onClose();
            }}
            data-testid="closeModalButton"
          >
            <CloseIcon />
          </button>
        </div>
        <div>{children}</div>
      </div>
    </ReactModal>
  );
}
