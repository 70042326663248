import Select from 'react-select';
import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DropDownStyle from '../../Dropdown/Components/DropDownStyle';
import { FormControl, FormField, FormItem, FormMessage } from '../../Form';

function DeskField() {
  const { desks } = useSelector((state) => state.loadCard);
  const { t } = useTranslation();

  return (
    <FormField
      name="desk"
      rules={{ required: t('Required') }}
      render={(field) => (
        <FormItem className="w-full md:w-1/2 xl:w-2/6 2xl:w-2/6">
          <FormControl>
            <Select
              id="deskDropdown"
              {...field}
              inputRef={field.ref}
              styles={DropDownStyle((option) => option)}
              placeholder={t('Desk')}
              options={desks}
              getOptionLabel={(option) => option.name}
              getOptionValue={(option) => option.id}
              isSearchable={true}
              components={{
                IndicatorSeparator: null,
              }}
              formatOptionLabel={(option) => (
                <span data-testid={`desk-` + option.name}>{option.name}</span>
              )}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}

export default DeskField;
