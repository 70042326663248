import { issuingAPI } from '../services/Axios';
import store from '../store/store';

const getTags = ({ perPage, page }) => {
  const { selectedTag } = store.getState().tag;
  const pagination = {
    per_page: perPage,
    page,
  };
  return issuingAPI.get(`tag/${selectedTag.id}/tagging`, {
    params: pagination,
  });
};

const createTag = (data) => {
  const { selectedTag } = store.getState().tag;
  return issuingAPI.post(`tag/${selectedTag.id}/tagging`, data);
};

const editTag = (id, data) => {
  const { selectedTag } = store.getState().tag;
  return issuingAPI.put(`tag/${selectedTag.id}/tagging/${id}`, data);
};

const deleteTag = (tagId) => {
  const { selectedTag } = store.getState().tag;
  return issuingAPI.delete(`tag/${selectedTag.id}/tagging/${tagId}`);
};

export default { getTags, createTag, editTag, deleteTag };
