import axios from 'axios';

export const fetchFeatureFlags = (tagId) => {
  if (window?.appConfig?.FEATURE_FLAGS_API_BASE_URL) {
    const params = {};

    if (window?.appConfig?.REACT_APP_AUTH_CLIENT_ID) {
      params.client_id = window?.appConfig.REACT_APP_AUTH_CLIENT_ID;
    }

    if (tagId) {
      params.tag_id = tagId;
    }

    return axios
      .get('feature-flags', {
        baseURL: window?.appConfig.FEATURE_FLAGS_API_BASE_URL,
        params,
      })
      .then((response) => {
        window.appConfig.flags = response.data;
      });
  }

  return Promise.resolve([]);
};
